<template>
  <div class="wrapper">
    <div class="not-found-title">404</div>
    <div class="page-subtitle">
      Pedimos desculpa mas a página que procura não existe!
    </div>
    <div class="back-wrapper">
      <router-link class="btn-back" to="/">Voltar</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "not-found",
  components: {},
  data() {
    return {
      cta: [
        {
          buttonLink: "/",
          buttonLabel: "Voltar",
          btnClass: "hero-btn",
          anchor: true,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.back-wrapper {
  display: flex;
  justify-content: center;
  margin: 4rem;
  .btn-back {
    color: $primaryColor;
    cursor: pointer;
    display: flex;
    font-size: $mainFontSize;
    padding: 0.75rem 1.5rem;
    text-align: center;
    text-decoration: none;
    transition: 0.05s all ease;
    width: fit-content;
    min-height: 55px;
    place-items: center;
    justify-content: center;
    background-color: $primaryColor;
    border: thin solid $primaryColor;
    color: $primaryWhiteColor;
    width: 200px;
  }
}

.not-found-title {
  font-size: calc($biggestFontSize * 2);
  text-transform: uppercase;
  margin: 4rem;
  &::after {
    display: block;
    clear: both;
    content: "";
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 150px;
    height: 1px;
    width: 30%; /* or 100px */
    border-bottom: 4px solid $brandColor;
    margin: 0 auto;
    padding: 4px 0px;
  }
}
</style>
